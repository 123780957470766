
.carousel-container {
   position: relative;
   width: 100%;
   height: 100vh;
   overflow: hidden;
 }

 .carousel-video {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }
 
 .carousel-video video {
   object-fit: cover;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: -1;
 }

 .carousel-prev,
.carousel-next {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: color 0.2s ease-in-out;
}

.carousel-prev:hover,
.carousel-next:hover {
  color: #ccc;
  cursor: pointer;
}

.carousel-prev {
  left: 1rem;
}

.carousel-next {
  right: 1rem;
}

 