* {
   box-sizing: border-box;
}

body {
   margin: 0;
   background: #333;
   color: white;
}

.nav {
   background-color: slategrey;
   color: white;
   display: flex;
   justify-content: space-between;
   align-items: stretch;
   gap: 2rem;
   padding: 0 1rem;
}

.site-title {
   font-size: 2rem;
}

.nav ul {
   padding: 0;
   margin: 0;
   list-style: none;
   display: flex;
   gap: 1rem;
}

.nav a {
   color: inherit;
   text-decoration: none;
   height: 100%;
   display: flex;
   align-items: center;
   padding: .25rem;
}

.nav li:hover {
   background-color: beige;
}

.video-container {
   height: 100vh;
   position: relative;
   overflow: hidden;

   @media(min-width: 600px) {
      height: 100vh;
   }
}

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
   object-fit: cover;
   position: absolute;
   height: 100vh;
   width: 100%;
   right: 0;
   bottom: 0;
}

#aboutImg {
   width: 100%;
}

.video-container:after {
   height: 100%;
   width: 100%;
   position: absolute;
   top: 0;
   left: 0;
   background: rgba(black, .2);
   z-index: 1;
}

.about {
   padding: 0;
   margin: 0;
   width: 100%;
   height: 100vh;
}

.home-description {
   position: absolute;
}

.container {
   margin-bottom: 3.25in;
}


.checkout-button {
   position: relative;
   overflow: fill;
   display: inline-flex;
   top: 55px;
   left: 200px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 400px;
   /* background: #00028A;
   border: 20px solid rgba(0, 0, 0, 0.0);
   box-shadow: -2px 10px 5px rgba(0, 0, 0, 0);
   border-radius: 10px;
   border-bottom: 50px ridge rgba(0, 2, 138, 0.0); */
   font-family: SQ Market, Helvetica, Arial, sans-serif;
}

.checkout-button img {
   width: 46.36vh;
   height: 60vh;
   border-radius: 10px;
   background: #00028A;
   border: 20px solid rgba(0, 0, 0, 0.0);
}

.checkout-image {
   width: 46.36vh;
   height: 60vh;
   border-radius: 10px;
   background: #00028A;
   border: 20px solid rgba(0, 0, 0, 0.0);
}

.checkout-button a {
   display: inline-block;
   font-size: 18px;
   line-height: 48px;
   height: 48px;
   color: #ffffff;
   min-width: 212px;
   background-color:  #00028A;
   text-align: center;
   box-shadow: 0 0 0 1px rgba(0, 0, 0, .1) inset;
}